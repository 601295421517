<template>
  <nav>
    <div class="container">
      <div class="row">
        <div class="col">
          <img src="./assets/logo.png" width="80">
        </div>
        <div class="col-lg-9 border border-white rounded-5 d-flex justify-content-around align-items-center">
            <router-link to="/">ACCUEIL</router-link>
            <!--<router-link to="/">BILLETERIE</router-link> 
            <router-link to="/">INFORMATIONS</router-link> -->
            <router-link to="/about">CONTACT</router-link> 
            <!--<router-link to="/">PROCHAINE EVENEMENT HORS FESTIVAL</router-link> |
            <router-link to="/">PROGRAMMATION</router-link> |
            <router-link to="/">BILLETERIE</router-link> |
            <router-link to="/">BÉNÉVOLES</router-link> |
            <router-link to="/">POSTULATIONS</router-link> |
            <router-link to="/">INFORMATIONS</router-link> |
            <router-link to="/">CONTACT</router-link> |
            <router-link to="/">CONNEXION</router-link>-->
        </div>
      </div>
    </div>
  </nav>
  <router-view/>
</template>


<style>
@font-face{
  font-family:"Milk";
  src:url('~@/assets/Fonts/Titre_lemon_milk/LEMONMILK-Regular.otf');
}
@font-face{
  font-family:"cunfonfonts";
  src:url('~@/assets/Fonts/Texte_neutraface-text-cufonfonts/Neutraface Text Book.otf');
}
#app {
  font-family: cunfonfonts,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #dbdbdb;
  background-color:black;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
p {
  font-size:20pt;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
